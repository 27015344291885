import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Provision Portal Workflow`}</h1>
    <p>{`In each User Guide, per release, we document the actual release Workflow names and steps but this is not that. We will cover what’s happening behind the scenes.`}</p>
    <h2>{`What is a Workflow?`}</h2>
    <p>{`A Workflow in PP is simply a bunch of Automation Documents, grouped together to produce an desired end state system (server or device).
Automation Documents are an AWS SSM requirement to use SSM to run automation scripts on an target system.
These documents are where we use parameters entered into the Deployment Profile to check and set environment variables required to lay down our software.`}</p>
    <h2>{`Server Flow`}</h2>
    <ul>
      <li parentName="ul">{`Component = Server Type or Server Types where the SSM Agent is installed`}
        <ul parentName="li">
          <li parentName="ul">{`AIO/APP/ISS/DB/DataSync`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Workflows = Versioned grouped automation steps for a target system`}
        <ul parentName="li">
          <li parentName="ul">{`AIO Clean, 3 Box Migration, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Workflow Steps = Friendly named Automation Documents that execute one or many Steps`}
        <ul parentName="li">
          <li parentName="ul">{`Deploy Database Server, Update DataSync Values, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Steps = Individual script executions per Automation Document. Can be one or many.`}</li>
    </ul>
    <h3>{`All together:`}</h3>
    <p>{`Component => Workflow => Workflow Steps (Automation Docs) => Steps (Script/Command executions)`}</p>
    <h2>{`Steps (Most Important)`}</h2>
    <p>{`Steps are the actual script executions like downloading a file or running a script. This is the only thing that matters when it comes to runtime.`}</p>
    <h3>{`What Steps can do`}</h3>
    <ul>
      <li parentName="ul">{`Run PowerShell inline`}</li>
      <li parentName="ul">{`Download files`}</li>
      <li parentName="ul">{`Execute Choco packages`}</li>
    </ul>
    <h3>{`Logging`}</h3>
    <p>{`Each step produces its own logs. Therefore, the author of each step is responsible for logging that steps output. Not all steps will have logs because not all steps have meaningful outputs.
PP reports the logs back from SSM and SSM limits that output to 48,000 characters.`}</p>
    <p>{`More complete logs should be pulled from the target system, if needed.`}</p>
    <h3>{`Scripts/Choco packs`}</h3>
    <p>{`We run PowerShell scripts, SQL scripts, and Choco packs (PowerShell scripts too but for BD Software). Each script must own its own logic and error handling.
This is a requirement per AWS SSM tooling. The tools have no knowledge of what is being executed so the developer must tell the tool what to do.`}</p>
    <h3>{`Error Handling`}</h3>
    <p>{`Developers must tell SSM when it should error out, besides a timeout as SSM will handle that error natively. This means dev’s have to throw an exception when there is a failure.
Checks for state should also be managed in the script itself as SSM nor Provision Portal manages that.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      